import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => ({ isLogin: false, userInfo: {} }),
  actions: {
    setLogin(login: boolean) {
      this.isLogin = login
    },
  },
  persist: true,
})
