<template>
  <page-meta :page-style="themeStore.fontSizeStyle.contentFontSize"></page-meta>
  <view :style="themeStore.fontSizeStyle.style">
    <wd-config-provider :themeVars="themeStore.fontSizeStyle.themeVars">
      <slot />
      <wd-toast />
      <wd-message-box custom-class="whitespace-pre-wrap" />
    </wd-config-provider>
  </view>
</template>

<script lang="ts" setup>
import { useThemeStore } from '@/store/theme'

const themeStore = useThemeStore()
</script>
