<template>
  <wd-config-provider :themeVars="themeVars">
    <template v-if="envVersion === 'release'">
      <slot />
    </template>
    <OnlineComponent v-else />
    <wd-toast />
    <wd-message-box custom-class="whitespace-pre-wrap" />
  </wd-config-provider>
</template>

<script lang="ts" setup>
import OnlineComponent from '@/components/OnlineComponent.vue'
import type { ConfigProviderThemeVars } from 'wot-design-uni'

const themeVars: ConfigProviderThemeVars = {
  // colorTheme: 'red',
  // buttonPrimaryBgColor: '#07c160',
  // buttonPrimaryColor: '#07c160',
}

const accountInfo = uni.getAccountInfoSync()
const envVersion = accountInfo.miniProgram.envVersion
</script>
